import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../common/ChartDynamicColor";
import { ApexOptions } from "apexcharts";

const StackedColumnChart: React.FC = () => {
  // Ensure colors are passed as an array
  const dataColors='["#642e5c", "--bs-warning", "--bs-success"]' 

  const stackedColumnChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Series 1",
      data: [34, 44, 54, 21, 12, 43, 33, 23, 66, 66, 58, 72]
    },
    {
      name: "Series 2",
      data: [22, 29, 37, 45, 32, 34, 52, 42, 42, 54, 36, 51]
    },
    {
      name: "Series 3",
      data: [11, 17, 25, 22, 13, 32, 44, 32, 34, 52, 41, 41]
    }
  ];

  const options: ApexOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="260"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default StackedColumnChart;
