import { getItem, key, removeItem, setItem } from "./local_storage_service";
let  marketplaceKey = "b74e67bfc1AFO";  //b74e67bfc1AFO  219fe95d32AXR
let token: string | null = "";
let apiType = "G";
let isLogin = false;

function getToken() {
  // debugger
  if (!token) {
    token = getItem("token");
    if (token) {
      apiType = "C";
      isLogin = true;
    } else {
      token = marketplaceKey || "";
      apiType = "G";
      isLogin = false;
    }
  }
  return token;
}

function getUserType() {

    return process.env.userType || ""; 

  }
 

function removeToken() {
  token = "";
  apiType = "G";
  isLogin = false;
  return removeItem("token");
}

function setToken(value: string) {
  setItem("token", value);
  apiType = "C";
  token = value;
  isLogin = true;
}

function setComapnyToken(value: string) {
  setItem("companyToken", value);
  apiType = "C";
  token = value;
  isLogin = true;
}

function isAuthenticated() {
  if (!token) {
    token = getItem("token");
    if (token) {
      apiType = "C";
      isLogin = true;
    } else {
      token = marketplaceKey || null;
      apiType = "G";
      isLogin = false;
    }
  }
  return isLogin;
}

function getAPIType() {
  return apiType;
}

function getEncKey() {
  return "lgisNuvnutHHODcTSjT4PDihrMl1MqzgxIA+bfrGipw=";
}

function getDataTag() {
  return process.env.dataTag || "";
}

function getChannel() {
  return process.env.channel || "";
}

function getPlatformSalt() {
  return process.env.platformSalt || "";
}

function setCookie(name: string, value: string, daysToExpire: number): void {

  var expirationDate = new Date(daysToExpire * 1000);

  //const expirationDate = new Date();
  // expirationDate.setTime(
  //   expirationDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000
  // );
  const expires = "expires=" + expirationDate.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name: string): string | null {
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function removeCookie(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export {
  getToken,
  removeToken,
  setToken,
  getAPIType,
  getEncKey,
  getDataTag,
  getChannel,
  isAuthenticated,
  getPlatformSalt,
  setComapnyToken,
  setCookie,
  getCookie,
  removeCookie,
  getUserType
};
