import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import styles from './partrner-register-modal.module.scss';
import { setCookie, setToken } from "assistance/user_service";
import { createAccount } from "assistance/identity_service";
import createPromoterClient from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  formData: any;
  
}

const OTPModal: React.FC<OTPModalProps> = ({ isOpen, toggle, formData }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) { // Allow only single digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field or go back on delete
      if (value && index < otp.length - 1) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      } else if (!value && index > 0) {
        (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
      }
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
  
    if (otp.includes("")) {
      setOtpError(true);
      return;
    } else {
      setOtpError(false);
    }
  
    const { email, password, username, phone, country, type } = formData;
    const accountType = type === "Affiliate" ? "PROMOTER" : type === "Agency" ? "BUSINESSUSER" : "";
  
    // Concatenate the OTP array into a single string
    const otpString = otp.join("");
  
    try {
      const response = await createAccount(
        username, 
        email, 
        password, 
        "EMAIL", 
        otpString, // Pass the concatenated OTP string here
        "PROMOTER",
        "U3"
      );
  
      if (response.status === 200 || response.status === 203) {
        var companyToken = response?.data?.data?.token;
        setToken(companyToken);
      
        if (companyToken) {
          //const decodedString = atob(companyToken);

          const [header, payload, signature] = companyToken.split(".");
          const decodedPayload = JSON.parse(atob(payload));

          if (decodedPayload) {
            const expireDate = decodedPayload?.exp;

           setCookie("token", companyToken, expireDate);
          }
        }

        //UserService.setCookie("token", companyToken, 7);
        // localStorage.setItem("companyToken", companyToken);
        // localStorage.setItem("companyToken", companyToken);
        setTimeout(() => {
          createPartner(companyToken);
        }, 1000);
      } else if (response.status === 202) {
        // Handle other responses
      }
    } catch (error) {
      throw error;
    }
  };
  

  async function createPartner(companyToken: string) {
    if (formData) {
      const firstName = formData?.username;
      const emailval = formData?.email;
      const phoneNumber = formData?.phone; // Corrected from phonee
      
      createPromoterClient(
        "",
        firstName,
        emailval,
        phoneNumber,
        
      ).then((response) => {
        if (response.status === 200) {
          // router.push("/dashboard");
          window.location.href = "/dashboard"; 
        } else if (response.status === 202) {
          window.location.href = "/dashboard"; 
        } else {
          // Handle error
        }
      });
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`}>
      <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
      <ModalBody>
        <div className={styles["otp-inputs"]}>
          {otp.map((digit, index) => (
            <div className="m-2" key={index}>
              <Input
                type="text"
                id={`otp-input-${index}`}
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
              />
            </div>
          ))}
        </div>
        {otpError && <p className={styles.errorText}>Please enter the full OTP.</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={submitForm}>Verify</Button>
      </ModalFooter>
    </Modal>
  );
};

export default OTPModal;
