import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const Activity: React.FC = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Notification</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">  22 Nov <i className="bx bx-right-arrow-alt font-size-16  align-middle ms-2"  style={{ color: "#642e5c" }}/>  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Responded to need “Volunteer Activities</div>
                </div>
              </div>
            </li>

            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14"> 17 Nov <i className="bx bx-right-arrow-alt font-size-16  align-middle ms-2"  style={{ color: "#642e5c" }}/> </h5>
                </div>
                <div className="flex-grow-1">
                  <div id="activitytext">
                    Everyone realizes why a new common language would be
                    desirable...<Link to="#">Read More</Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" style={{ backgroundColor: "#642e5c" ,color: "white" }} />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14"> 15 Nov <i className="bx bx-right-arrow-alt font-size-16  align-middle ms-2"  style={{ color: "#642e5c" }}/> </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Joined the group “Boardsmanship Forum”</div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">22 Nov<i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2"  style={{ color: "#642e5c" }}/>  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Responded to need “In-Kind Opportunity”</div>
                </div>
              </div>
            </li>
          </ul>
          <div className="text-center mt-4">
            <Link to="#" className="btn text-white waves-effect waves-light btn-sm"   style={{ background: "#642e5c" }}>View More <i className="mdi mdi-arrow-right ms-1"   style={{ color: "white" }}/></Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Activity;