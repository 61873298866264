import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register";
import LeadDashboard from "pages/LeadRegister";
import Chat from "pages/Chat/Chat";
import KYCApplication from "pages/kyc/kyc";
import ManageProfile from "pages/manage-profile/manage-profile";
import Partner from "Components/partner/partner";
import Pages404 from "pages/errorPages/404error";

const authProtectedRoutes = [
  { path: `/dashboard`, component: <Dashboard /> },
  { path: `/leadregister`, component: <LeadDashboard /> },
  { path: `/profile`, component: <UserProfile /> },
  { path: `/client`, component: <Chat /> },
  { path: `/kycapplication`, component: <KYCApplication /> },
  { path: "/manageProfile", component: <ManageProfile /> },
  // Wildcard route for auth-protected routes to show 404 if no match
  { path: "*", component: <Pages404 /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
  { path: `/`, exact: true, component: <SignUp /> },
  // Wildcard route for public routes to show 404 if no match
  { path: "*", component: <Pages404 /> },
];

export { authProtectedRoutes, publicRoutes };
