import {
  clientPostService,
    identityPostService,
  postService,
} from "./http_service";
import { identity_baseUrl } from "./const";
const IdentityBaseUrl = identity_baseUrl;

async function verifyClientAccount(
  username: string,
  identityType: string,
  actionType: string,
  businessType: string
) {
  //identityType = setIdentityType(username, identityType, actionType, businessType);
  let createAccountData = {
    identityValue: username,
    identityType: identityType,
    otpFlag: "N",
    otpFor: actionType,
  };
  return clientPostService(
    IdentityBaseUrl + "/client/account/verify",
    createAccountData,
    "verifyAccountClient",
    businessType
  );
}

// function setIdentityType(username: string, identityType: string) {
//   if (identityType === "") {
//     if (checkNumeric(username)) {
//       identityType = "PHONE";
//     } else {
//       identityType = "EMAIL";
//     }
//   }
//   return identityType;
// }

// function checkNumeric(n) {
//   return !isNaN(parseFloat(n)) && isFinite(n);
// }

async function createAccount(
  name: string,
  identityValue: string | number,
  password: string,
  identityType: string,
  otp: string | number,
  businessType: string,
  userRole: string

) {
  let createAccountData = {
    identityValue: identityValue,
    identityType: identityType,
    otp: otp,
    name: name,
    password: btoa(password),
    appId : "000026",
    userRole: userRole
  };
  return clientPostService(
    IdentityBaseUrl + "/client/account/signup",
    createAccountData,
    "signupAccountClient",
    businessType
  );
}

async function loginUser(
  loginType: string,
  identityValue: string | number,
  password: string,
  identityType: string,
  otp: string | number,
  businessType: string
) {
  let createAccountData = {
    identityValue: identityValue,
    identityType: identityType,
    password: password,
    otp: otp,
    loginType: loginType,
  };
  return identityPostService(
    IdentityBaseUrl + "/client/account/signin",
    createAccountData,
    "signinAccountClient",
    businessType
  );
}

async function logout() {
  let logoutData = {
    pathParams: {},
    queryParams: {},
    data: {},
  };
  return postService(IdentityBaseUrl + "/logout", logoutData, "logoutUser");
}

async function socialLogin(
  identityValue: string | number,
  identityType: string,
  identityValue2: string | number,
  identityType2: string,
  name: string,
  socialId: string,
  socialType: string,
  socialAccessToken: string,
  businessType: string,
  socialLoginType: string
) {
  let createAccountData = {
    identityValue: identityValue,
    identityType: identityType,
    identityValue2: identityValue2,
    identityType2: identityType2,
    name: name,
    socialId: socialId,
    socialType: socialType,
    socialAccessToken: socialAccessToken,
    businessType: businessType,
    socialLoginType: socialLoginType
  };
  return identityPostService(
    IdentityBaseUrl + "/client/account/sociallogin",
    createAccountData,
    "socialLoginAccountClient",
    businessType
  );
}

async function forgetPassword(

  identityValue: string | number,
  password: string,
  identityType: string,
  otp: string | number,
  businessType: string
) {
  let createAccountData = {
    identityValue: identityValue,
    identityType: identityType,
    password: password,
    otp: otp,
  };
  return identityPostService(
    IdentityBaseUrl + "/client/account/forgotpassword",
    createAccountData,
    "forgotPasswordAccountClient",
    businessType
  );
}


export { verifyClientAccount, createAccount, loginUser, logout, socialLogin, forgetPassword };
