import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const MonthlyEarning = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col sm={5}>
              <CardTitle className="mb-4">Monthly Earning</CardTitle>
              <h3>$34,252</h3>
              <div className="mt-4">
                <Link to="#" className="btn text-white waves-effect waves-light btn-sm" style={{ backgroundColor: "#642e5c" }}>
                  View More <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>

            {/* Divider */}
            <Col sm={2} className="d-flex justify-content-center align-items-center">
              <div style={{ width: "2px", height: "100%", backgroundColor: "#ddd" }}></div>
            </Col>

            <Col sm={5}>
              <CardTitle className="mb-4">Last Payment</CardTitle>
              <h3>$34,252</h3>
              <div className="mt-4">
                <Link to="#" className="btn text-white waves-effect waves-light btn-sm" style={{ backgroundColor: "#642e5c" }}>
                  View More <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
