import { promoter_baseUrl } from "./const";
import { getService, getServicePin } from "./http_service";
const PromoterBaseUrl = promoter_baseUrl;


export default function getCountryList() {
  return getService(PromoterBaseUrl + "mcountry", "getCountry");
}

async function getStates(id) {
  return getService(PromoterBaseUrl + `mstates?countryId=${id}`, "getState");
}

async function getPinCode(pincode,countryCode) {
  return getServicePin(PromoterBaseUrl + "pincode/"+pincode+"?countryCode="+countryCode,"getDetailsByPincode");
}

export{
  getPinCode,
  getStates
}

