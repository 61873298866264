import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { RecentChat, Contact, Group } from "./type";
import Spinners from "Components/Common/Spinner";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import avatar1 from "../../assets/images/users/avatar-1.jpg";

interface Props {
    userChatOpen: (chats: RecentChat) => void;
    currentRoomId: number
}


const ChatList: React.FC<Props> = ({ userChatOpen, currentRoomId }) => {

    const dispatch = useDispatch<any>();

    const [menu1, setMenu1] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string | number>("chat");

 
    const currentUser = {
        name: "Henry Wells",
        isActive: true,
    };

    // change tab 
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };


 
    return (
        <React.Fragment>
            <div className="" >
                <div >
                    <div className="chat-leftsidebar-nav  "  >
                        <Nav pills justified>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === "chat", })  } style={{ backgroundColor: activeTab === "chat" ? "#642e5c" : "white" }}  onClick={() => { toggleTab("chat"); }}>
                                    <i className="bx bx-chat font-size-30 d-sm-none" />
                                    <span className="d-none d-sm-block">Active Paying clients</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === "groups", })} style={{ backgroundColor: activeTab === "groups" ? "#642e5c" : "white" }} onClick={() => { toggleTab("groups"); }}>
                                    <i className="bx bx-group font-size-30 d-sm-none" />
                                    <span className="d-none d-sm-block">Premium Clients</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === "contacts", })} style={{ backgroundColor: activeTab === "contacts" ? "#642e5c" : "white" }} onClick={() => { toggleTab("contacts"); }}>
                                    <i className="bx bx-book-content font-size-30 d-sm-none" />
                                    <span className="d-none d-sm-block">Cancelled Clients</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ChatList;