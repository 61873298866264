
//production
export const career_baseUrl ="https://fcareer.skartio.app/careerframe/api/110";
export const promoter_baseUrl ="https://fpromoter.skartio.app/promoterframe/api/110/";
export const identity_baseUrl ="https://identity.skartio.app/identityframe/api/110";
export const search_baseUrl = "https://recruitsearch.skartio.app/job";
export const search_baseUrl_Company = "https://recruitsearch.skartio.app";
export const blog_baseUrl_Company = "https://fblog.skartio.app/blogframe/api/110";
//staging
// export const career_baseUrl ="http://192.168.1.210:20710/buyerframe/api/110";
//  export const promoter_baseUrl ="http://192.168.1.210:29710/promoterframe/api/110/";
//  export const identity_baseUrl ="http://192.168.1.210:20310/identityframe/api/110";
// export const search_baseUrl = "http://192.168.1.210:15210/job";


// SKARTIOELETRONICS : 39fb2455fcABQ
// SKARTIOFASHION  : b03c1e7a34ABV
// SKARTIOGROCERY  : 3ff37c4c32ABW
// MOBILE          : b1d790325eAKW
