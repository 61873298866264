import { isAuthenticated } from "assistance/user_service";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const AuthProtected = (props) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    if (isAuthenticated()) {
      console.log("gghgj")
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  if (isAuth === false) {
    return <Navigate to="/login" />;
  }

  if (isAuth === null) {
    // Optional: Add a loading indicator
    return null;
  }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AuthProtected;
