import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardTitle,
  CardSubtitle,
  NavItem,
  Nav,
  NavLink,
  Input,
} from "reactstrap";
import { getPincodeDetail } from "assistance/promoter_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import {
  addLeadGen,
  getAction,
  getAllLeadGen,
  getProfile,
  updateLeadStatus,
} from "assistance/promoterClient_service";
// import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Country {
  id: number;
  sortname: string;
  name: string;
  phoneCode: number;
}

const BasicTable = () => {
  const [subScribeModal, setSubScribeModal] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [qualified, setQualified] = useState<boolean>(false);
  const [converted, setconverted] = useState<boolean>(false);
  const [nonconverted, setnonconverted] = useState<boolean>(false);
  const [searchValues, setSearchValues] = useState("");
  const [actionSearch, setActionSearch] = useState<boolean>(false);

  const [collapse, setCollapse] = useState<boolean>(false);
  // const leadDataArray = Array.isArray(leadData) ? leadData : [];
  const [leadid, setLeadId] = useState("");
  const [inputValuePincode, setInputValuePincode] = useState("");
  const [pinCodeError, setPinCodeError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>("all");
  const [activeTabDes, setActiveTabDes] = useState<string>("");
  const [profileData, setProfileData] = useState("");
const[kycFlag,setKycFlag]=useState<boolean>(false);

const [showToast, setShowToast] = useState(false);

useEffect(() => {
  const timer = setTimeout(() => {
    setShowToast(false);
  }, 1000); // 1000 milliseconds = 1 second

  return () => clearTimeout(timer); // Clear timeout if component unmounts
}, [showToast]);


const handleCodeChange = (e) => {
  setSelectedCode(e.target.value);
};


  const [formData, setFormData] = useState({
    businessName: "",
    contactPerson: "",
    phone: "",
    email: "",
    pincode: "",
    country: "",
    countrycode: "",
    note: "",
  });


  const updates = () => {
    getProfile().then((response) => {
      if (response.status === 200) {
        console.log(response?.data?.data);
        setProfileData(response?.data?.data);
        setKycFlag(response?.data?.data?.onboardingSteps?.kycFlag)
       
      }
    });
  };

  useEffect(() => {

    
    updates();
  },[])



  const [validation, setValidation] = useState<any>({});
  const [validPincode, setValidPincode] = useState(false);
  const [officeName, setOfficeName] = useState("");
  const [leadData, setLeadData] = useState("");
  const [selectedCode, setSelectedCode] = useState("91");
  const [phoneNum, setPhoneNum] = useState('');
  const [countryCodeVal, setCountryCodeVal] = useState("");
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [allLead, setAllLead] = useState("");
  // const countryList = [
  //   { name: "India", phoneCode: "91" },
  //   { name: "USA", phoneCode: "1" },
  //   { name: "UK", phoneCode: "44" },
  //   // add more countries here
  // ];

  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if(name === 'phone'){
      setPhoneNum(value)
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChanges = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if(name === 'phone'){
      setPhoneNum(value)
    }

    const combinedPhone = `${selectedCode}${" "}${value}`;

    setFormData((prev) => ({
      ...prev,
      phone:  "+"+combinedPhone,
    }));
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    console.log(e.target.value);
    const [countryName, countryCode] = selectedCountry.split(",");
    const country = countryList.find((c) => c.name === selectedCountry);
    setCountryCodeVal(country?.phoneCode.toString() || "");
    // Update form data with selected country

    setFormData((prevData) => ({
      ...prevData,
      country: countryName,
      countrycode: countryCode,
    }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   country: `${countryName},${countryCode}`,
    //   countrycode: countryCode,
    // }));
    


  };
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: { [key: string]: string } = {};
    if (!formData.businessName)
      errors["businessName"] = "Business Name is required.";
    if (!formData.contactPerson)
      errors["contactPerson"] = "Contact Person is required.";
    if (!formData.phone) errors["phone"] = "Phone is required.";
    if (!formData.pincode) errors["pincode"] = "Pincode is required.";
    if (!formData.country) errors["country"] = "Country is required.";

    setValidation(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted:", formData);
  //  if(kycFlag === true){
      try {
        const response = await addLeadGen(
          
          formData?.phone,
          formData?.businessName,
          formData?.pincode,
          formData?.contactPerson,
          formData?.email,
          formData?.countrycode,
          formData?.country
        );
        
        if (response.data && response.status === 200) {
          console.log(response.data.data); // Log the fetched state name
          setShowToast(true)
          // Clear the form data after successful submission
          setFormData({
            businessName: "",
            contactPerson: "",
            phone: "",
            email: "",
            pincode: "",
            country: "",
            countrycode: "",
            note: "", // Add the missing `note` field
          });
          setSubScribeModal(!subScribeModal);
          toast.success("Added Lead Successfully", { autoClose: 2000 });

          // getAllLead();
        } else if (response.status === 203) {
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
          setFormData({
            businessName: "",
            contactPerson: "",
            phone: "",
            email: "",
            pincode: "",
            country: "",
            countrycode: "",
            note: "", // Add the missing `note` field
          });
          setSubScribeModal(!subScribeModal);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    // }else{
    //   console.log("Error kycghgd ");
    //   // setFormData({
    //   //   businessName: "",
    //   //   contactPerson: "",
    //   //   phone: "",
    //   //   email: "",
    //   //   pincode: "",
    //   //   country: "",
    //   //   countrycode: "",
    //   //   note: "", // Add the missing `note` field
    //   // });
    //   // setSubScribeModal(!subScribeModal);
    // }
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      } finally {
        // setLoading(false);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    if (activeTab === "all") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        let leadStatus = "";
        getAllLead(catId, leadStatus, searchValue);
      } else {
        let catId = "09";
        let leadStatus = "";
        getAllLead(catId, leadStatus, searchValues);
      }
    } else if (activeTab === "L10") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValue);
      } else {
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValues);
      }
    } else if (activeTab === "L11") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValue);
      } else {
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValues);
      }
    } else if (activeTab === "L12") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValue);
      } else {
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValues);
      }
    } else if (activeTab === "L13") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValue);
      } else {
        if (searchValues === " ") {
          let searchValue = "";
          let catId = "09";
          // let leadStatus = "";
          getAllLead(catId, activeTab, searchValue);
        } else {
          let catId = "09";
          // let leadStatus = "";
          getAllLead(catId, activeTab, searchValues);
        }
      }
    } else if (activeTab === "L14") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValue);
      } else {
        let catId = "09";
        // let leadStatus = "";
        getAllLead(catId, activeTab, searchValues);
      }
    }
  }, [
    subScribeModal,
    activeTab,
    isDelete,
    qualified,
    converted,
    nonconverted,
    actionSearch,
    
  ]);

  const getAllLead = async (
    catId: string,
    leadStatus: string,
    searchValue: string
  ) => {
    try {
      const lead = await getAllLeadGen(catId, leadStatus, searchValue); // Call the function from JS file
      setAllLead(lead?.data?.data?.data);

    } catch (err) {
      console.error("Error fetching leads:", err);
      toast.error("Failed to fetch leads. Please try again.");
    } finally {
      // setLoading(false);
    }
  };
  // Pincode validation function
  async function checkPincodeAvailability(pincode: string) {
    if (pincode !== "") {
      setLoader(true);

      try {
        const response = await getPincodeDetail(pincode);

        if (response.status === 200) {
          setValidPincode(true);
          setPinCodeError(null);

          let location = "";
          let locationData = response?.data?.data;
          let countryCodeData = "";

          locationData?.forEach(
            (item: {
              officeType: string;
              officename: string;
              countryCodeData: string;
            }) => {
              if (item?.officeType === "S.O") {
                location = item?.officename;
                countryCodeData = item?.countryCodeData;
                setOfficeName(location);
                setCountryCodeVal(countryCodeData);
              }
            }
          );

          setLoader(false);
        } else {
          setPinCodeError("Failed to fetch pincode details");
          setLoader(false);
          setValidPincode(false);
        }
      } catch (error) {
        setPinCodeError("Error fetching pincode details");
        setLoader(false);
        setValidPincode(false);
      }
    } else {
      setPinCodeError("Enter your pincode");
    }
  }
  // console.log("lead",allLead)
  // change tab
  const toggleTab = (tab: any, des: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveTabDes(des);
    }
  };

  useEffect(() => {
    const getAllAction = async () => {
      try {
        const action = await getAction(); // Call the function from JS file
        console.log(action?.data?.data?.leadSettings?.leadStatus);
        setLeadData(action?.data?.data?.leadSettings?.leadStatus);
      } catch (err) {
        console.error("Error fetching countries:", err);
      } finally {
        // setLoading(false);
      }
    };
    getAllAction();
  }, []);

  const handleNotQualified = async () => {
    let desc = activeTabDes;
    let statusId = "L12";
    try {
      // Make sure to await the function correctly
      const response = await updateLeadStatus(leadid, desc, statusId);

      if (response.data && response.status === 200) {
        console.log(`Lead ID ${leadid} status updated successfully`);
        toast.success(`Lead status updated successfully`);

        // Add any additional success logic here
        setIsDelete(!isDelete);
      }
    } catch (error) {
      console.error(
        `Failed to update lead status for Lead ID ${leadid}:`,
        error
      );
    }
  };
  const handleQualified = async () => {
    let desc = activeTabDes;
    let statusId = "L11";
    try {
      // Make sure to await the function correctly
      const response = await updateLeadStatus(leadid, desc, statusId);

      if (response.data && response.status === 200) {
        console.log(`Lead ID ${leadid} status updated successfully`);
        // Add any additional success logic here
        toast.success(`Lead status updated successfully`);

        setQualified(!qualified);
      }
    } catch (error) {
      console.error(
        `Failed to update lead status for Lead ID ${leadid}:`,
        error
      );
    }
  };
  const handleNonConverted = async () => {
    let desc = activeTabDes;
    let statusId = "L14";
    try {
      // Make sure to await the function correctly
      const response = await updateLeadStatus(leadid, desc, statusId);

      if (response.data && response.status === 200) {
        console.log(`Lead ID ${leadid} status updated successfully`);
        
    
      
        toast.success(`Lead status updated successfully`);
        
        setnonconverted(!nonconverted);
        // Add any additional success logic here
      }
    } catch (error) {
      console.error(
        `Failed to update lead status for Lead ID ${leadid}:`,
        error
      );
    }
  };
  const handleConverted = async () => {
    let desc = activeTabDes;
    let statusId = "L13";
    try {
      // Make sure to await the function correctly
      const response = await updateLeadStatus(leadid, desc, statusId);

      if (response.data && response.status === 200) {
        console.log(`Lead ID ${leadid} status updated successfully`);
        toast.success(`Lead status updated successfully`);

        // Add any additional success logic here
        setconverted(!converted);
      }
    } catch (error) {
      console.error(
        `Failed to update lead status for Lead ID ${leadid}:`,
        error
      );
    }
  };
  const handleNQ = (id: React.SetStateAction<string>) => {
    setLeadId(id);

    setIsDelete(true);
  };
  const handleQ = (id: React.SetStateAction<string>) => {
    setLeadId(id);
    setQualified(true);
  };
  const handleNC = (id: React.SetStateAction<string>) => {
    setLeadId(id);
    setnonconverted(true);
  };
  const handleC = (id: React.SetStateAction<string>) => {
    setLeadId(id);
    setconverted(true);
  };
  const handleSearchValue = (value) => {
    console.log(value);
    setSearchValues(value);
  };

  const handlesearchs = () => {
    setActionSearch(!actionSearch);
  };




  const [fontSize, setFontSize] = useState(getFontSize());

  // Helper function to determine the font size based on the window width
  function getFontSize() {
    if (window.innerWidth >= 1200) return "14px";
    else if (window.innerWidth >= 1030) return "10px";
    return "10px";
  }

  useEffect(() => {
    // Event handler to update font size on resize
    const handleResize = () => setFontSize(getFontSize());

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <React.Fragment>
            <ToastContainer/>

      <div className="container-fluid">
        <form className="mb-4">
          <div className="g-3 row" >
            <div className="col-lg-3 col-xxl-3  col-md-3">
              <div className="position-relative mt-1">
                <input
                  id="searchJob"
                  placeholder="Search your Lead"
                  type="text"
                  className="form-control "
                  onChange={(e) => handleSearchValue(e.target.value)}
                />{" "}
              </div>
            </div>

            <div className="col-lg-3 col-xxl-2 col-md-3">
              <div className="position-relative h-100 hstack gap-3">
                <button
                  type="button"
                  className="btn text-white  w-100 "
                  style={{ backgroundColor: "#642e5c" }}
                  onClick={() => handlesearchs()}
                >
                  <i className="bx bx-search-alt align-middle"></i> Find Leads
                </button>
              </div>
            </div>

            {activeTab === "all" ? (
                  <>
            <div className="col-lg-3 col-xxl-2 col-md-3">
              <div className="position-relative h-100 hstack gap-3">
              

                    <button
                      className="btn btn-secondary  w-100 "
                      onClick={() => setCollapse(true)}
                    >
                      <i className="bx bx-filter-alt align-middle"></i>
                      Filter
                    </button>
             
              </div>
            </div>
            </>

   ) : (
    ""
  )}
            <div className="col-lg-3 col-xxl-3 col-md-3 ">
              <Row className="">
                <Col className="text-start d-flex justify-content-end">
                  <Button
                    className="font-size-15 w-md   w-100 "
                    style={{
                      backgroundColor: "white",
                      // borderColor: "#642e5c",
                      border: "2px solid #642e5c",
                      color: "#642e5c",
                    }}
                    onClick={() => setSubScribeModal(true)}
                  >
                    + Add Lead
                  </Button>
                </Col>
              </Row>
            </div>

            {activeTab === "all" ? (
              <>
                {/* {collapse == true ? (
                  <div id="collapseExample" className="collapse show">
                    <div>
                      <div className="g-3 row">
                        <div className="col-lg-6 col-xxl-4">
                          <div>
                            <label className="form-label fw-semibold form-label">
                              Experience
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox1"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option1"
                            />
                            <label className="form-check-label form-label">
                              All
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox2"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option1"
                            />
                            <label className="form-check-label form-label">
                              Fresher
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox3"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option2"
                            />
                            <label className="form-check-label form-label">
                              1-2
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox4"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option2"
                            />
                            <label className="form-check-label form-label">
                              2-3
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox5"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              4+
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4">
                          <div>
                            <label className="form-label fw-semibold form-label">
                              Job Type
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox6"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Full Time
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox7"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Part Time
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox8"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Freelance
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox9"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Internship
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </>
            ) : (
              ""
            )}
          </div>
        </form>

        <Nav pills justified>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "all" })}
              style={{
                backgroundColor: activeTab === "all" ? "#642e5c" : "white",
                fontSize: fontSize,
              }}
              onClick={() => {
                toggleTab("all", "all lead");
              }}
            >
              <i className="bx bx-chat font-size-30 d-sm-none" />
              <span className="d-none d-sm-block">All Lead</span>
            </NavLink>
          </NavItem>

          {Array.isArray(leadData) &&
            leadData.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === item?.status })}
                  style={{
                    backgroundColor:
                      activeTab === item?.status ? "#642e5c" : "white",
                      fontSize: fontSize,
                  }}                  onClick={() =>
                    toggleTab(item?.status, item?.statusCustomDesc)
                  }
                >
                  <i className="bx bx-chat font-size-30 d-sm-none" />
                  <span className="d-none d-sm-block">
                    {item?.statusCustomDesc}
                  </span>
                </NavLink>
              </NavItem>
            ))}
        </Nav>

        {allLead && (
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4"> </CardTitle>
                  <CardSubtitle className="card-title-desc"> </CardSubtitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Business Name</th>
                          <th>Contact Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>
                            {activeTab === "all" ? (
                              <> Status</>
                            ) : activeTab === "L10" ? (
                              <></>
                            ) : activeTab === "L11" ? (
                              <> </>
                            ) : activeTab === "L12" ? (
                              <></>
                            ) : activeTab === "L13" ? (
                              <> </>
                            ) : activeTab === "L14" ? (
                              <></>
                            ) : (
                              ""
                            )}
                          </th>
                          <th>
                            {activeTab === "all" ? (
                              <> </>
                            ) : activeTab === "L10" ? (
                              <>Action</>
                            ) : activeTab === "L11" ? (
                              <> Action</>
                            ) : activeTab === "L12" ? (
                              <></>
                            ) : activeTab === "L13" ? (
                              <> </>
                            ) : activeTab === "L14" ? (
                              <></>
                            ) : (
                              ""
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(allLead) &&
                          allLead.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.leadName}</td>
                              <td>{item.additionalInfo?.pocName}</td>
                              <td>{item.additionalInfo?.pocEmail}</td>
                              <td>{item.leadUniqueID}</td>

                              <td>
                                {activeTab === "all" ? (
                                  <>
                                    {" "}
                                    {item.leadStatus === "L10" ? (
                                      <>
                                        <span className="bg-info badge bg-secondary">
                                          New
                                        </span>
                                      </>
                                    ) : item.leadStatus === "L11" ? (
                                      <>
                                        <span className="bg-success badge bg-secondary">
                                          Qualified
                                        </span>
                                      </>
                                    ) : item.leadStatus === "L12" ? (
                                      <>
                                        <span className="bg-danger badge bg-secondary">
                                          UnQualified
                                        </span>
                                      </>
                                    ) : item.leadStatus === "L13" ? (
                                      <>
                                        <span className="bg-success badge bg-secondary">
                                          Converted
                                        </span>
                                      </>
                                    ) : item.leadStatus === "L14" ? (
                                      <>
                                        <span className="bg-danger badge bg-secondary">
                                          Non Converted
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : activeTab === "L10" ? (
                                  <> </>
                                ) : activeTab === "L11" ? (
                                  <> </>
                                ) : activeTab === "L12" ? (
                                  <></>
                                ) : activeTab === "L13" ? (
                                  <> </>
                                ) : activeTab === "L14" ? (
                                  <></>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {" "}
                                {activeTab === "all" ? (
                                  <> </>
                                ) : activeTab === "L10" ? (
                                  <>
                                    <div className="list-unstyled hstack gap-1 mb-0">
                                      <li>
                                        <button
                                          className="btn btn-sm btn-soft-primary"
                                          onClick={() => handleQ(item?.leadId)}
                                        >
                                          Qualified
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="btn btn-sm btn-soft-danger"
                                          onClick={() => handleNQ(item?.leadId)}
                                        >
                                          UnQualified
                                          </button>
                                      </li>
                                    </div>
                                  </>
                                ) : activeTab === "L11" ? (
                                  <>
                                    {" "}
                                    <div className="list-unstyled hstack gap-1 mb-0">
                                      <li>
                                        <button
                                          className="btn btn-sm btn-soft-primary"
                                          onClick={() => handleC(item?.leadId)}
                                        >
                                          Converted
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="btn btn-sm btn-soft-danger"
                                          onClick={() => handleNC(item?.leadId)}
                                        >
                                          Non Converted
                                        </button>
                                      </li>
                                    </div>
                                  </>
                                ) : activeTab === "L12" ? (
                                  <></>
                                ) : activeTab === "L13" ? (
                                  <> </>
                                ) : activeTab === "L14" ? (
                                  <></>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Basic Tables" /> */}
      </div>
      <Modal
        isOpen={subScribeModal}
        centered
        toggle={() => setSubScribeModal(!subScribeModal)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setSubScribeModal(!subScribeModal)}
        />
        <ModalBody>
          <form className="needs-validation" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Business Name</label>
                  <input
                    name="businessName"
                    placeholder="Lead name"
                    type="text"
                    className={`form-control ${
                      validation.businessName ? "is-invalid" : ""
                    }`}
                    value={formData.businessName}
                    onChange={handleInputChange}
                  />
                  {validation.businessName && (
                    <div className="invalid-feedback">
                      {validation.businessName}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Contact Person</label>
                  <input
                    name="contactPerson"
                    className={`form-control ${
                      validation.contactPerson ? "is-invalid" : ""
                    }`}
                    placeholder="Contact Person"
                    value={formData.contactPerson}
                    onChange={handleInputChange}
                  />
                  {validation.contactPerson && (
                    <div className="invalid-feedback">
                      {validation.contactPerson}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                <label className="form-label">Phone</label>

                <div className="input-group">

                  <select
                            className="form-select"
                            value={selectedCode}
                            onChange={handleCodeChange} // Ensure this is being handled properly
                            style={{ maxWidth: "60px" }}
                          >
                            {countryList &&
                              countryList.map((country, index) => (
                                <option
                                  key={index}
                                  value={country.phoneCode.toString()}
                                >
                                  {country.phoneCode}
                                </option>
                              ))}
                          </select>
                          <Input
                            className="form-control"
                            type="tel"
                            name="phone"
                            autoComplete="off"
                            // value={phoneNum || ""}
                            onChange={handleInputChanges}
                            onBlur={validation.handleBlur}
                            placeholder="Enter phone number"
                            // invalid={
                            //   validation.touched.phone &&
                            //   validation.errors.phone
                            //     ? true
                            //     : false
                            // }
                          />
                     </div>
                  {validation.phone && (
                    <div className="invalid-feedback">{validation.phone}</div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    name="email"
                    className={`form-control`}
                    placeholder="Enter Email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {/* {validation.email && <div className="invalid-feedback">{validation.email}</div>} */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Current Country</label>
                  <select
                    name="country"
                    className={`form-select ${
                      validation.country ? "is-invalid" : ""
                    }`}
                    // value={formData.country}
                    value={`${formData.country},${formData.countrycode}`}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select Country</option>
                    {countryList &&
                      countryList.map((country, index) => (
                        <option
                          key={index}
                          value={`${country.name},${country.sortname}`}
                        >
                          {country.name}
                        </option>
                      ))}
                  </select>
                  {validation.country && (
                    <div className="invalid-feedback">{validation.country}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Pin code</label>
                  <input
                    name="pincode"
                    className={`form-control ${
                      validation.pincode ? "is-invalid" : ""
                    }`}
                    placeholder="Pincode*"
                    value={formData.pincode}
                    onChange={(e) => {
                      handleInputChange(e);
                      setInputValuePincode(e.target.value);
                    }}
                    onBlur={(e) => checkPincodeAvailability(e.target.value)}
                    autoComplete="off"
                  />
                  {pinCodeError && (
                    <span className="text-danger">{pinCodeError}</span>
                  )}
                  {validation.pincode && (
                    <div className="invalid-feedback">{validation.pincode}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Notes</label>
                  <textarea
                    name="note"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formData.note}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button type="submit" style={{ backgroundColor: "#642e5c" }}>
                Add Lead
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {/* <ToastContainer /> */}
      <Modal isOpen={isDelete} centered toggle={() => setIsDelete(!isDelete)}>
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setIsDelete(!isDelete)}
        />
        <ModalBody>
          <form className="needs-validation">
            <div className="px-4 text-center modal-body">
        
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to change lead to Not Qualified
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleNotQualified()}
                >
                  Not Qualified
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsDelete(!isDelete)}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={qualified}
        centered
        toggle={() => setQualified(!qualified)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setQualified(!qualified)}
        />
        <ModalBody>
          <form className="needs-validation">
            <div className="px-4 text-center modal-body">
             
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to change lead to Qualified
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleQualified()}
                >
                  Qualified
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setQualified(!qualified)}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={converted}
        centered
        toggle={() => setconverted(!converted)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setconverted(!converted)}
        />
        <ModalBody>
          <form className="needs-validation">
            <div className="px-4 text-center modal-body">
              
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to change lead to Converted
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleConverted()}
                >
                  Converted
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setconverted(!converted)}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={nonconverted}
        centered
        toggle={() => setnonconverted(!nonconverted)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setnonconverted(!nonconverted)}
        />
        <ModalBody>
          <form className="needs-validation">
            <div className="px-4 text-center modal-body">
              
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to change lead to Non Converted
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleNonConverted()}
                >
                  Non Converted
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setnonconverted(!nonconverted)}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default BasicTable;
