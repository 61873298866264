import {
  clientPostService,
  deleteService,
  getService,
  postService,
  putService,
} from "./http_service";
import { promoter_baseUrl } from "./const";
const PromoterBaseUrl = promoter_baseUrl;

async function getCountryList() {
  return getService(PromoterBaseUrl + "/mcountry", "getCountry");
}

async function getAvailablePincode(pincode: string) {
  return getService(
    PromoterBaseUrl + "pincode/" + pincode + "?countryCode=IN",
    "getDetailsByPincode"
  );
}
async function getPincodeDetail(pincode: string) {
  return getService(
    PromoterBaseUrl + "/listpincode/" + pincode + "?countryCode=",
    "getDetailsByPincode"
  );
}
async function createPromoter(
  firstName: string,
  lastName: string,
  pincode: string,
  // gender: string,
  country: string,
  city: string
) {
  let createAccountData = {
    firstName: firstName,
    lastName: lastName,
    pincode: pincode,
    // gender: gender,
    country: country,
    city: city,
  };
  return postService(
    PromoterBaseUrl + "bizowner",
    createAccountData,
    "createBizOwner"
  );
}

async function getPromoterProfile() {
  return getService(PromoterBaseUrl + "promoter", "getPromoter");
}

async function uploadContent(file: any) {
  let formData = new FormData();
  formData.append("attachment", file);

  return postService(
    PromoterBaseUrl + "/promoter/uploadavatar",
    formData,
    "updatePromoterAvatar"
  );
}



async function updatePromoter(
  firstName: string,
  pincode: number,
  gender: string,
  city: string
) {
  let createAccountData = {
    firstName: firstName,
    pincode: pincode,
    gender: gender,
    city: city,
  };
  return putService(
    PromoterBaseUrl + "promoter",
    createAccountData,
    "updatePromoter"
  );
}

async function addProductCategory(
  name: string,
  hasParent: number,
  desc: string,
  hasChild: number,
  parentId: number,
  parentName: string,
  status: string
) {
  let createAccountData = {
    name: name,
    hasParent: hasParent,
    hasChild: hasChild,
    parentId: parentId,
    parentName: parentName,
    status: status,
    desc: desc,
  };
  return postService(
    PromoterBaseUrl + "bizowner/productaff/category",
    createAccountData,
    "createBizownerProductCategory"
  );
}

async function updateProductCategory(
  name: string,
  hasParent: number,
  desc: string,
  hasChild: number,
  parentId: number,
  parentName: string,
  status: string,
  catID: string
) {
  let createAccountData = {
    name: name,
    hasParent: hasParent,
    hasChild: hasChild,
    parentId: parentId,
    parentName: parentName,
    status: status,
    desc: desc,
  };
  return putService(
    PromoterBaseUrl + "bizowner/productaff/category/" + catID,
    createAccountData,
    "updateBizownerProductCategory"
  );
}

async function getProductCategory(catID: string) {
  return getService(
    PromoterBaseUrl + "bizowner/productaff/category/" + catID,
    "getBizownerProductCategory"
  );
}

async function getAllProductCategory(keyword: string, pageNum: number, pageSize: number) {
  return getService(
    PromoterBaseUrl +
      "/bizowner/productaff/category?pageNum="+pageNum+"&pageSize="+pageSize+"&sortOrder=desc&keyword="+keyword+"&status",
    "getAllBizownerProductCategory"
  );
}

async function imageUpload(file: any, ContentCategory: string) {
  let formData = new FormData();
  let contentTag: string[] = [""];

  formData.append("ContentType", "Image");
  formData.append("ContentCategory", ContentCategory);
  formData.append("ContentText", "");
  formData.append("ContentTag", JSON.stringify(contentTag));
  formData.append("file", file);
  formData.append("folderName", "logo");
  formData.append("SubContentType", "");
  formData.append("contentGroup", ContentCategory);
  formData.append("visibilityStatus", "PUBLIC");

  return postService(PromoterBaseUrl + "/content", formData, "uploadContent");
}

async function createProduct(
  productName: string,
  currency: string,
  productContent: any,
  productDesc: string,
  productCategory: any,
) {
  let createAccountData = {
    prdBasic: {
      type: "",
      name: productName,
      status: "",
      statusDesc: "",
      desc: "",
      longDesc: productDesc ? productDesc : "",
      qualityLevel: "",
      countryOfOrigin: "",
    },
   
    currency: currency,
    prdCategory: productCategory,
    prdContent: productContent,
    
  };
  return postService(
    PromoterBaseUrl + "productaff/products",
    createAccountData,
    "createProduct"
  );
}

async function updateProductprice(
  productId: string,
  priceType: string,
  actualPrice: number,
  minPercentage: number
) {
  let createAccountData = {
    priceType: priceType,
    actualPrice: actualPrice,
    minPercentage: minPercentage,
  };
  return putService(
    PromoterBaseUrl + "productaff/products/" + productId + "/price",
    createAccountData,
    "updateProductPrice"
  );
}

async function updateProductpriceSchemes(
  productId: string,
  priceType: string,
  pricebracket : any
) {
  let createAccountData = {
	  priceType: priceType,
	  brackets: pricebracket
};
  return putService(
    PromoterBaseUrl + "productaff/products/" + productId + "/price",
    createAccountData,
    "updateProductPrice"
  );
}

async function getAllProducts(keyword: string, pageNum: number, pageSize: number) {
  return getService(
    PromoterBaseUrl +
      "/productaff/products?pageNum="+pageNum+"&pageSize="+pageSize+"&sortOrder=desc&keyword="+keyword,
    "getAllProduct"
  );
}

async function getProductPriceData(productId: string) {
  return getService(
    PromoterBaseUrl + "/productaff/products/" + productId + "/price",
    "getProductPrice"
  );
}

async function getProductByID(productId: string) {
  return getService(
    PromoterBaseUrl + "productaff/products/" + productId,
    "getProduct"
  );
}

async function updateProduct(
  productId: string,
  productName: string,
  currency: string,
  productContent: any,
  productDesc: string,
  productCategory: any,
  status: string,
  statusDesc: string,
) {
  let createAccountData = {
    prdBasic: {
      type: "",
      name: productName,
      status: status,
      statusDesc: statusDesc,
      desc: "",
      longDesc: productDesc ? productDesc : "",
      qualityLevel: "",
      countryOfOrigin: "",
    },
    currency: currency,
    prdCategory: productCategory,
    prdContent: productContent
  };
  return putService(
    PromoterBaseUrl + "productaff/products/" + productId,
    createAccountData,
    "updateProduct"
  );
}

async function getBizownerLeads(
  productIds: any,
  leadsIds: string,
  fromDate: Date,
  toDate: Date,
  dateFilter: string,
  claimed: string,
  leadStatus: string
) {
  let createAccountData = {
    dateFilter: dateFilter,
    fromDate: fromDate,
    toDate: toDate,
    productId: productIds,
    leadId: leadsIds,
  };
  return putService(
    PromoterBaseUrl +
      "/bizowner/lead?pageNum=1&pageSize=100&sortOrder=desc&keyword=&category=&claimStatus=&leadStatus="+leadStatus+"&origin=&claimedLeads=" +
      claimed,
    createAccountData,
    "getAllBizOwnerLeads"
  );
}

async function claimLeads(leadId: string) {
  let createAccountData = {};
  return postService(
    PromoterBaseUrl + "bizowner/lead/" + leadId + "/claim",
    createAccountData,
    "claimPromoterLeadbyBizOwner"
  );
}

async function deleteProduct(productID: string) {
  return deleteService(
    PromoterBaseUrl + "/productaff/products/" + productID,
    "deleteProduct"
  );
}

async function updateChangeLeadStatus(
  leadId: string,
  leadStatus: string,
  leadStatusDesc: string,
  note: string
) {
  let createAccountData = {
    leadStatus: leadStatus,
    leadStatusDesc: leadStatusDesc,
    note: note
  };
  return putService(
    PromoterBaseUrl + "promoterleads/" + leadId + "/status",
    createAccountData,
    "updatePromoterLeadStatusAdmin"
  );
}


async function getAllLeadsCount() {
  return getService(
    PromoterBaseUrl +
    "bizowner/leadanalytics/productaff",
    "getBizOwnerLeadProductAffCount"
  );
}


async function updateProductCommissions(
  productId: string,
  commissionBasedOn: string,
  commissionValue: number,
  commissionPrg: number,
) {
  let createAccountData = {
    value:commissionValue,
    percentage:commissionPrg,
    basedOn: commissionBasedOn
  };
  return putService(
    PromoterBaseUrl + "productaff/products/" + productId + "/bizcommission",
    createAccountData,
    "updateProductBizCommission"
  );
}

async function getProductCommission(productID: string) {
  return getService(
    PromoterBaseUrl +
    "/productaff/products/"+productID+"/bizcommission",
    "getProductBizCommission"
  );
}


async function getAllActiveProducts(
  category: any,
  sortType: string,
) {
  let createAccountData = {
    category: category,
    sortType: sortType
  };
  return postService(
    PromoterBaseUrl +
      "productlist?pageNum=1&pageSize=10&sortOrder=desc&keyword=",
    createAccountData,
    "getAllActiveProductList"
  );
}

// async function getAllActiveProducts() {
//   return getService(
//     PromoterBaseUrl + "productlist?pageNum=1&pageSize=10&sortOrder=desc&keyword=",
//     "getAllActiveProductList"
//   );
// }


export {
  getCountryList,
  getAvailablePincode,
  createPromoter,
  getPromoterProfile,
  uploadContent,
  getPincodeDetail,
  updatePromoter,
  addProductCategory,
  updateProductCategory,
  getProductCategory,
  getAllProductCategory,
  imageUpload,
  createProduct,
  getAllProducts,
  updateProductprice,
  getProductPriceData,
  getProductByID,
  updateProduct,
  getBizownerLeads,
  claimLeads,
  deleteProduct,
  updateChangeLeadStatus,
  updateProductpriceSchemes,
  getAllLeadsCount,
  updateProductCommissions,
  getProductCommission,
  getAllActiveProducts
};
