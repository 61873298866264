import React from 'react';
import { Container } from 'reactstrap';
import Breadcrumb from 'Components/Common/Breadcrumb';
import BasicTable from 'Components/dashboard/dashboard';

const LeadDashboard = () => {
  document.title = "Lead - Dashboards | Skartio - Insider";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Own Lead" breadcrumbItem="Own Lead" />
        

                     <BasicTable/> 
        </Container>
      </div>
    </React.Fragment >
  );
}

export default LeadDashboard;