// registerFunctions.js
import { createAccount, verifyClientAccount } from "assistance/identity_service";
import getCountryList from "assistance/static_api";
import OTPModal from "Components/partner-register-modal/partner-register-modal";
import Partner from "Components/partner/partner";
import { useState } from 'react'; // Import useState if you are in a functional component
import { Router } from "react-router-dom";

// Fetch the country list from the API
export const fetchCountryList = async () => {
  try {
    const response = await getCountryList();
    if (response.data.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Error fetching country list");
    }
  } catch (error) {
    throw error;
  }
};

// Verify email via client account service
export const verifyEmail = async (finalData, setShowOTP) => {
  // debugger
  const identityVal = finalData?.email;
  const identityType = "EMAIL";
  const actionType = ""; // Adjust based on requirements

  try {
    const response = await verifyClientAccount(identityVal, identityType, actionType, "U3");
    
    if (response.status === 200) {
      window.location.href = "/login"; 


      // return { status: "success", message: "OTP sent" };
    } else if (response.status === 202) {
      return { status: "warning", message: "Number already linked with another account" };
    } else if (response.status === 203) {
      // Call verifyUser function to create the account with the finalData
      await verifyUser(finalData, setShowOTP); // Pass setShowOTP to verifyUser
      return { status: "success", message: "Account creation in progress" };
    } else {
      throw new Error("General verification failure");
    }
  } catch (error) {
    throw error;
  }
};

// Function to verify user and create an account
const verifyUser = async (finalData, setShowOTP) => {
  const { email, password, username, phone, country, type } = finalData;
  const otp = ""; // Assuming no OTP initially for account creation

  // Determine the account type based on the provided type
  const accountType = type === "Affiliate" ? "PROMOTER" : type === "Agency" ? "PROMOTER" : "";

  try {
    const response = await createAccount(
      username, 
      email, 
      password, 
      "EMAIL", 
      otp, 
      "U3" // Use the determined account type
    );

    if (response.status === 200 || response.status === 203) {
      // If account exists or creation succeeded, redirect to login
      setTimeout(() => {
        // router.push({
        //   pathname: "/login",
        //   // query: {
        //   //   inputValue: email,
        //   //   identityType: "EMAIL",
        //   // },
        // });
        


        
      }, 3000);
    } else if (response.status === 202) {
      // Show the OTP modal
      setShowOTP(true);
    }
  } catch (error) {
    throw error;
  }
};
