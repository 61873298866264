import { promoter_baseUrl } from "./const";
import { getService, postPromoter, postPromoterService, postService, putKYC } from "./http_service";
const PromoterBaseUrl = promoter_baseUrl;

export default function createPromoterClient(
  referralCode: string, // expect a string type for referralCode
  name: string,         // expect a string type for name
  email: string,        // expect a string type for email
  mobile: string        // expect a string type for mobile
) {
  let createAccountData = {
    referralCode: referralCode,
    name: name,
    email: email,
    mobile: mobile,
  };

  return postPromoterService(
    PromoterBaseUrl + "/user",
    createAccountData,
    "createUserProfile"
    
  );
}


async function getApplicant() {
  return getService( PromoterBaseUrl+ "/applicant", "getApplicant");
}


// get details of promoter

async function getProfile() {
  return getService( PromoterBaseUrl+ "/promoter", "getPromoter");
}




async function getDocType() {
  return getService( PromoterBaseUrl+ "/settings/static/identitydocumenttypes", "getIdentityDocumentTypes");
}

async function updatesProfile(
  firstName: string,
  lastName:string,
  // emailval: string,
  // emailVerified: string,
  // phoneVerified: string,
  pincode: string,
  // gender: string,
  country: string,
  state:string,

) {
  let createAccountData = {
    firstName: firstName,
    lastName: lastName,

    // email: emailval,
    // emailVerified: emailVerified,
    // phoneVerified: phoneVerified,
    pincode: pincode,
    // gender: gender,
    country: country,
    state: state,
  };
  return postPromoter(
    PromoterBaseUrl + "promoter",
    createAccountData,
    "updatePromoter"
  );
}



async function addKyc(
  name: string,
  gender: string,
  DOB: Date,
  permanentPin: string,
  IdType: string,
  IdNumber: string,
 
) {
  let createAccountData = {
    name: name,
    gender: gender,
    DOB: DOB,
    permanentPin: permanentPin,
    IdType: IdType,
    IdNumber:IdNumber,
    address: [
    ],
  };
  return putKYC(
    PromoterBaseUrl + "promoter/kyc",
    createAccountData,
    "updatePromoterKYC"
  );
}


async function kycDoc(

  document:File
) {
  // debugger
  let createAccountData = {
    document:document,
  };
  return postPromoter(
    PromoterBaseUrl + "promoter",
    createAccountData,
    "updatePromoter"
  );
}

async function addLeadGen(
  Phone:string,
  leadName:string,
  pincode:string,
  pocName:string,
  pocEmail:any,
  countryCode:string,
  countryName:string
) {
  // debugger
  let createAccountData = {
    channelID: "C1",
    channelName: "PromoterApp",
    programId: "P1",
    programName: "Promoter Program",
    leadCategoryId: "09",
    leadCategoryName: "Platform Lead",
    leadUniqueIdType: "P",
    leadUniqueID: Phone,
    leadName: leadName,
    pincode: pincode,
    origin: "",
    additionalInfo: {
      pocName: pocName,
      pocPhone: "",
      pocEmail: pocEmail,
      address: {
        line1: "",
        line2: "",
        countryCode: countryCode,
        countryName: countryName
      }
    }
  
  };
  return postPromoterService(
    PromoterBaseUrl + "promoter/leadgen",
    createAccountData,
    "createPromoterLeadGen"
  );
}


// async function getAllLeadGen(catId:string) {
//   return getService( PromoterBaseUrl+ "promoter/leadgen?pageNum=1&pageSize=5&sortOrder=desc&keyword=&category="+catId,
//      "getAllPromoterLead");
// }



async function getAllLeadGen(catId:string,leadStatus:string,searchValue:string) {
  return getService( PromoterBaseUrl+ "promoter/leadgen?pageNum=1&pageSize=10&sortOrder=desc&keyword="+searchValue+"&category="+catId+"&leadStatus="+leadStatus,
     "getAllPromoterLead");
}


async function getAction() {
  return getService( PromoterBaseUrl+ "settings",
     "getPromoterSettings");
}

async function updateLeadStatus(

  leadId:string,
  leadStatusDesc:string,
  statusId:string
) {
  // debugger
  let createAccountData = {
    leadStatus:statusId,
    leadStatusDesc:leadStatusDesc
  };
  return postPromoter(
    PromoterBaseUrl + "promoter/leadgen/"+leadId+"/status",
    createAccountData,
    "updatePromoterLeadStatus"
  );
}



export {
  getApplicant,
  getProfile,
  updatesProfile,
  addKyc,
  getDocType,
  kycDoc,
  addLeadGen,
  getAllLeadGen,
  getAction,
  updateLeadStatus
}



