import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Row,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import KYCApplication from "pages/kyc/kyc";
import { getProfile, updatesProfile } from "assistance/promoterClient_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import { getPinCode } from "assistance/static_api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Country {
  name: string;
  phoneCode: string;
  sortname: string;
}

interface FormData {
  firstname: string;
  lastname: string;
  country: string;
  countryCode:string;
  state: string;
  pinCode: string;
}

interface Errors {
  firstname?: string;
  lastname?: string;
  country?: string;
  state?: string;
  pinCode?: string;
}

const ManageProfile: React.FC = () => {
  const [countryCodeVal, setCountryCodeVal] = useState("");
  const [codeVal, setCodeVal] = useState("");
  const [toast2, setToast2] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sortName, setSortName] = useState("");
  const [profileData, setProfileData] = useState("");
  const [stateName, setStateName] = useState("");

  // Meta title


  // console.log(countryList);

  const updates = () => {
    getProfile().then((response) => {
      if (response.status === 200) {
        
        console.log(response?.data?.data);
        setProfileData(response?.data?.data);
        
        
  
        const countryName = response?.data?.data?.country;
        
        // Find the matching country in the countryList array
        const matchingCountry = countryList.find(country => country.name === countryName);
        
        setFormData({
          firstname: response?.data?.data?.firstName,
          lastname: response?.data?.data?.lastName,
          country: countryName,
          countryCode: matchingCountry ? matchingCountry.sortname : "", // Set countryCode to sortname if found
          state: "",
          pinCode: response?.data?.data?.pincode,
        });

        
      }
    });
  };
  

  useEffect(() => {
    // getProfile().then((response) => {
    //   if (response.status === 200) {
    //     console.log(response?.data?.data);
    //   }
    // });

    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
        if(countries){
          updates();
        }
        console.log(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };
    getCountries();


  }, []);

  const [formData, setFormData] = useState<FormData>({
    firstname: "",
    lastname: "",
    country: "",
    countryCode:"",
    state: "",
    pinCode: "",
  });

  const [errors, setErrors] = useState<Errors>({}); // State to hold validation errors
  const handleChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    console.log(e.target.value);
    const [countryName, countryCode] = selectedCountry.split(",");    
    setSelectedCountry(countryName);

    setFormData((prevData) => ({
      ...prevData,
      country: countryName, // Update the country property
      countryCode: countryCode,

    }));
    setSortName(countryCode);
  };


  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    console.log(e.target.value);
    const [countryName, countryCode] = selectedCountry.split(",");
    const country = countryList.find((c) => c.name === selectedCountry);
    setCountryCodeVal(country?.phoneCode.toString() || ""); 
    // Update form data with selected country

    setFormData((prevData) => ({
      ...prevData,
      country: countryName,
      countryCode: countryCode,
    }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   country: `${countryName},${countryCode}`,
    //   countrycode: countryCode,
    // }));
    


  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear errors for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: Errors = {};
    if (!formData.firstname) {
      newErrors.firstname = "First name is required";
    }
    if (!formData.lastname) {
      newErrors.lastname = "Last name is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }
    if (!stateName) {
      newErrors.state = "state is required";
    }
    if (!formData.pinCode) {
      newErrors.pinCode = "Pin code is required";
    } else if (!/^\d{6}$/.test(formData.pinCode)) {
      newErrors.pinCode = "Pin code must be 5 digits";
    }

    setErrors(newErrors); // Update errors state
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validate()) {
      console.log("Form data submitted:", formData);

      const { firstname, lastname, pinCode } = formData;
      const payload = {
        firstName: firstname,
        lastName: lastname,
        pincode: pinCode,
        country: selectedCountry,
        state: stateName,
      };

      try {
        // Assuming `updatesProfile` requires 5 separate arguments
        const response = await updatesProfile(
          payload.firstName,
          payload.lastName,
          payload.pincode,
          payload.country,
          payload.state
        );

        // Check if response contains data
        if (response.data && response.status === 200) {
          console.log(response.data.data[0]?.statename); // Log the fetched state name
           
          updates();
          // Show the toast message (implement this as needed)
        } else if (response.status === 203) {
          // Handle specific status code (203) scenario here
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
        } else {
          // Handle other unexpected statuses
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    }
  };

  useEffect(() => {
    const fetchPinCodeData = async () => {
      try {
        const response = await getPinCode(formData?.pinCode, sortName); // Provide necessary arguments

        // Check if response contains data
        if (response.data && response.status === 200) {
          console.log(response.data.data[0]?.statename); // Set the fetched countries (assuming the actual data is in response.data.data)
          setStateName(response.data.data[0]?.statename); // Show the toast message
        } else if (response.status === 203) {
          setToast2(true);

          // Handle case where response does not have the expected data
          console.error("No data found or status is not 200:", response);
          setTimeout(() => {
            setToast2(false);
          }, 2000);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    fetchPinCodeData();
  }, [formData?.pinCode]);

  return (
    <React.Fragment>

<ToastContainer/>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Skote" breadcrumbItem="Manage Profile" />
   
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Basic Details</h4>
                  <Form className="needs-validation">
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">First name</Label>
                          <Input
                            name="firstname"
                            placeholder="First name"
                            type="text"
                            onChange={handleChange}
                            value={formData.firstname}
                            invalid={!!errors.firstname} // Show error if exists
                          />
                          <FormFeedback>{errors.firstname}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Last name</Label>
                          <Input
                            name="lastname"
                            placeholder="Last name"
                            type="text"
                            onChange={handleChange}
                            value={formData.lastname}
                            invalid={!!errors.lastname}
                          />
                          <FormFeedback>{errors.lastname}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">Country</Label>
                          <select
                            name="country"
                            className={`form-select ${
                              errors.country ? "is-invalid" : ""
                            }`}
                            onChange={handleChanges}
                            value={`${formData.country},${formData.countryCode}`}
                            >
                            <option value="" >
                              Select Country
                            </option>
                            {countryList.map((country, index) => (
                              <option
                                key={index}
                                value={`${country.name},${country.sortname}`} // Ensure this format matches formData.country
                              >
                                {country.name}
                              </option>
                            ))}
                          </select>

                          <FormFeedback>{errors.country}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">Pin Code</Label>
                          <Input
                            name="pinCode"
                            placeholder="Pin Code"
                            type="text"
                            onChange={handleChange}
                            value={formData.pinCode}
                            invalid={!!errors.pinCode}
                          />
                          <FormFeedback>{errors.pinCode}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">State</Label>
                          <Input
                            name="state"
                            placeholder="state"
                            type="text"
                            onChange={handleChange}
                            value={stateName}
                            invalid={!!errors.state}
                          />
                          <FormFeedback>{errors.state}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      style={{ backgroundColor: "#642e5c" }}
                      onClick={handleSubmit}
                    >
                      Submit form
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <KYCApplication />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageProfile;
