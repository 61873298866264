import React from "react"
import { Card, CardBody, CardTitle, Progress, Table } from "reactstrap"

const TopCities: React.FC = () => {

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Watch List</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="mdi mdi-account-clock  display-4" style={{color: "#642e5c"}}/>
            </div>
            {/* <h3>1,456</h3>
            <p>San Francisco</p> */}
          </div>

          <div className="table-responsive ">
            <Table className="align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">San Francisco</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">1,456</h5>
                  </td>
                  <td>
                    <Progress value="94" color="primary" className="bg-transparent progress-sm" size="sm" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Los Angeles</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,123</h5>
                  </td>
                  <td>
                    <Progress value="82" color="success" className="bg-transparent progress-sm" size="sm" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">San Diego</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <Progress value="70" color="warning" className="bg-transparent progress-sm" size="sm" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
