import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";

//import images
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="https://insider.skartio.com" className="logo logo-dark">           
            <span className="logo-lg">
              {/* <img src={logoDark} alt="" height="17" /> */}
              Skartio - INSIDER
            </span>
          </Link>

          <Link to="https://insider.skartio.com" className="logo logo-light">
            {/* <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
              Skartio - INSIDER

            </span> */}

          
            <span className="logo-lg font-size-20 font-bold text-white">
              {/* <img src={logoLightPng} alt="" height="19" /> */}
              
              Skartio - INSIDER

            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;