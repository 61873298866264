
function getItem(key:string) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key) || null;

  } else {
    return null;
  }
}

function key(index:number) {
  if (typeof window !== 'undefined') {
    return localStorage.key(index);
  } else {
    return null;
  }
}

function removeItem(key:string) {
  if (typeof window !== 'undefined') {
    return localStorage.removeItem(key);
  } else {
    return null;
  }
}

function setItem(key:string, value:string) {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(key, value);
  } else {
    return null;
  }
}


export {
  getItem, key, removeItem, setItem
}
