import axios from "axios";
import {getToken,getAPIType,getDataTag,getChannel, removeToken, getPlatformSalt} from "./user_service";
const axiosApiInstance = axios.create();
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    // const value = await redisClient.get(rediskey)
    // const keys = JSON.parse(value)
    // config.headers = {
    //   'Authorization': `Bearer ${keys.access_token}`,
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
    return config;
  },
  (  error: any) => {
    return Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response: any) => {
  return response
}, async function (error: { config: any; response: { status: number; }; }) {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    // originalRequest._retry = true;
    // const access_token = await refreshAccessToken();
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //return axiosApiInstance(originalRequest);
    removeToken();
  }
  return Promise.reject(error);
});

export const getService = async (url: string, actionTag:string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };
  return await axiosApiInstance.get(url, { headers });
};

export const postService = async (url: string, params: any, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };

  return await axiosApiInstance.post(url, params, { headers });
};

export const putService = async (url: string, params:any, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };
  return await axiosApiInstance.put(url, params, { headers });
};


export const deleteService = async (url: string, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };
  return await axiosApiInstance.delete(url, { headers });
};

export const deleteServiceWithBody = async (url: string, params:any ,actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };
  return await axiosApiInstance.delete(url ,  { headers:headers ,data:params});
};

export async function postImageService(url: string, params:any, actionTag: string): Promise<any>{
  let headers = {
      Authorization: 'Bearer ' + getToken(),
      actiontag: actionTag,
      channel: "web",
      apitype: getAPIType(),
      responseFlag: "on",
      roleType: "U3"
    }
  return await axiosApiInstance.post(url, params, { headers: headers })
}


export const framePostService = async (url: string, params:any, actionTag: string, guestId: string=""): Promise<any> => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    guestId: guestId,
    roleType: "U3"
  };
  return await axiosApiInstance.post(url, params, { headers });
};


export const frameGetService = async (url: string, actionTag: string, guestId: string=""): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    guestId: guestId
  };
  return await axiosApiInstance.get(url, { headers });
};

export const frameDeleteService = async (url: string, actionTag: string, guestId: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    guestId: guestId
  };
  return await axiosApiInstance.delete(url, { headers });
};


export const clientPostService = async (url: string, params: any, actionTag: string, userType: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    userType: userType
  };
  return await axiosApiInstance.post(url, params, { headers });
};


export const identityPostService = async (url: string, params: any, actionTag: string, userType: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    userType: userType,
    platformSalt:getPlatformSalt()
  };
  return await axiosApiInstance.post(url, params, { headers });
};

// export const SearchGetService = async (url: string, actionTag:string): Promise<any> => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + getToken(),
//     actiontag: actionTag,
//     responseFlag: "on",
//     apiType: getAPIType(),
//     dataTag: "",
//   };
//   return await axiosApiInstance.get(url, { headers });
// };

export const SearchGetService = async (url: string, actionTag:string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel: getChannel()
  };
  return await axiosApiInstance.get(url, { headers });
};


export const postPromoterService = async (url: string, params: any, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: "",
    channel:"web",
    roleType : "U3",
  };

  return await axiosApiInstance.post(url, params, { headers });
};

export const postPromoter = async (url: string, params: any, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
        actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: "",
    channel:"web",
    roleType : "U3",
  };

  return await axiosApiInstance.put(url, params, { headers });
};

let  marketplaceKey = "b74e67bfc1AFO";

export const getServicePin = async (url: string, actionTag:string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + marketplaceKey,
    actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: getDataTag(),
    channel:getChannel(),
    roleType: "U3"
  };
  return await axiosApiInstance.get(url, { headers });
};


export const putKYC = async (url: string, params: any, actionTag: string): Promise<any> => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + getToken(),
        actiontag: actionTag,
    responseFlag: "on",
    apiType: getAPIType(),
    dataTag: "",
    channel:"web",
    roleType : "U3",
  };

  return await axiosApiInstance.put(url, params, { headers });
};